







































































































































































import Vue from 'vue';
import moment from 'moment';
import * as couponApi from '@/api/couponTemplate';
export default Vue.extend({
  name: 'couponDetail',
  props: [],
  data() {
    return {
      showModal: false,
      basicData: {},
      sequenceNbr: '',
      formLoading: false, //表单加载状态
      tableData: [],
      tableLoading: false,
      searchForm: {
        agencyName: '',
      },
      filteredInfo: {
        source: [], // 派发方式 自动1 手动2
        hasUsed: [], // 使用状态	N：未使用，Y：已使用
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  computed: {
    tableColumns() {
      const tableColumns = [
        {
          title: '派发方式',
          dataIndex: 'source',
          align: 'center',
          scopedSlots: { customRender: 'source' },
          filters: [
            { text: '自动', value: 1 },
            { text: '手动', value: 2 },
          ],
          filteredValue: this['filteredInfo'].source,
        },
        {
          title: '派发时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
          align: 'center',
        },
        {
          title: '派发租户/个人',
          dataIndex: 'agencyName',
          align: 'center',
        },
        {
          title: '使用状态',
          dataIndex: 'hasUsed',
          align: 'center',
          scopedSlots: { customRender: 'hasUsed' },
          filters: [
            { text: '已使用', value: 'Y' },
            { text: '未使用', value: 'N' },
          ],
          filteredValue: this['filteredInfo'].hasUsed,
        },
        {
          title: '使用用户',
          dataIndex: 'usedUserName',
          align: 'center',
        },
        {
          title: '使用时间',
          dataIndex: 'usedTime',
          scopedSlots: { customRender: 'usedTime' },
          align: 'center',
        },
      ];
      return tableColumns;
    },
  },
  methods: {
    moment,
    show(id) {
      this.showModal = true;
      this.basicData = {};
      this.sequenceNbr = id;
      this.getCouponRecordsDetail();
      this.getCouponDetailTableData();
    },
    getCouponRecordsDetail() {
      this.formLoading = true;
      couponApi
        .getCouponRecordsDetail(this.sequenceNbr)
        .then((res) => {
          if (res.status === 200) {
            this.basicData = res.result;
          }
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getCouponDetailTableData();
    },
    // 组合查询表单重置
    handleSearchReset() {
      this.clearTableFilters();
      // (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.searchForm.agencyName = '';
      this.pagination.current = 1;
      this.getCouponDetailTableData();
    },
    // 对话框关闭事件
    handleModalCancel() {
      this.clearTableFilters();
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      // (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.searchForm.agencyName = '';
    },
    // 清空表格过滤条件
    clearTableFilters() {
      this.filteredInfo.source = [];
      this.filteredInfo.hasUsed = [];
    },
    getCouponDetailTableData() {
      this.tableLoading = true;
      const agencyName = this.searchForm.agencyName;
      const source = this.filteredInfo.source.join(',');
      const hasUsed = this.filteredInfo.hasUsed.join(',');
      couponApi
        .getCouponDetailTableData(
          this.sequenceNbr,
          agencyName,
          source,
          hasUsed,
          this.pagination.current,
          this.pagination.pageSize
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.tableData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 主列表翻页控制
    handleTableChange(pagination, filters) {
      // console.log(pagination,filters);
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.filteredInfo = filters;
      this.getCouponDetailTableData();
    },
  },
});
